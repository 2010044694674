import * as React from "react";
import { Link } from "gatsby";

import facebook from "../img/social/facebook.svg";
import twitter from "../img/social/twitter.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{backgroundColor: "#800000"}}>
        <div className="content has-text-centered">
          <div className="container">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/pages/executive-committee-current">
                        Executive Committee
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/events">
                        Events
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/news">
                        News
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/our-school">
                        Our School
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/our-principals">
                        Our Principals
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://fb.me/JVC.OSA.Canada">
                  <img src={facebook} alt="Facebook" style={{ width: "1em", height: "1em" }} />
                </a>
                <a title="twitter" href="https://twitter.com/JVCOSACanada">
                  <img className="fas fa-lg" src={twitter} alt="Twitter" style={{ width: "1em", height: "1em" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="content has-text-centered">
          <div className="container has-text-white-ter">
            2024 &copy; J/Victoria College, Chulipuram, Old Students Association, Canada (JVC OSA Canada).
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
